.sidenav {
  width: 200px;
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  height: 100%;
	padding-top: 20px;
}

.sidenav-list { 
  flex: 1;
	list-style: none;
	padding: 20px 0 0 0;
	margin: 0;
	width: 240px;
	height: 100%;
}

.sidenav-item {
	display: flex;
	align-items: center;
	padding: 14px 14px;
	margin: 6px 6px;
	cursor: pointer;
	transition: all 0.3s ease;
	color: rgba(255, 255, 255, 0.85);
	border-radius: 8px;
	font-weight: 500;
}

.sidenav-item:hover {
	background-color: rgba(255, 255, 255, 0.1);
	color: white;
	transform: translateX(4px);
}

.sidenav-item svg {
	margin-right: 12px;
	font-size: 20px;
	opacity: 0.9;
	transition: all 0.3s ease;
	min-width: 24px;
}

.sidenav-item:hover svg {
	opacity: 1;
	transform: scale(1.1);
}

#signout-button {
    position: absolute;
    bottom: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    border: none;
    background: transparent;
    font-size: 30px;
    box-sizing: border-box;
    text-align: left;
	color: rgba(255, 255, 255, 0.85);
}

#signout-button:hover svg {
	opacity: 1;
	transform: scale(1.1);
}

#signout-button.hover {
	background-color: rgba(255, 255, 255, 0.1);
	color: white;
	transform: translateX(4px);
}
  
.sidenav-list a {
	text-decoration: none;
	color: inherit;
	width: 100%;
}
